<template>
  <v-row>
    <slot
        :content="news.introduction"
        :date="news.date"
        :fullcontent="news.content"
        :index="index"
        :newsid="news.id"
        :title="news.title"
        name="item"
        v-for="(news, index) in data"
    />
  </v-row>
</template>

<script>
export default {
  name: 'news-overview',
  data() {
    return {
      paging: {
        next: null,
        page: 1,
      },
      data: null,
      searching: false,
      activity: true,
    };
  },
  props: {
    perPage: {
      default: 3,
      type: Number,
    },
  },
  methods: {
    getNews(next = false) {
      if (this.searching !== true) {
        if (!next) {
          this.page = 1;
          this.getNewsApiCall('news?perPage=' + this.perPage);
        } else {
          this.page++;
          this.getNewsApiCall('news?perPage=' + this.perPage + '&page=' + this.page, true);
        }
      }

    },
    getNewsApiCall(route, concatResults = false) {
      this.$emit('startSearching');
      this.$http.get(route).then((response) => {
        this.$emit('stopActivity');
        if (concatResults) {
          this.data = this.data.concat(response.data.data);
        } else {
          this.data = response.data.data;
        }
        this.paging.next = response.data.data['next_page_url'];
        if (this.paging.next === null) {
          this.$emit('noMoreResults');
        }
      });
    },
  },
  created() {
    this.$emit('startActivity');
    this.getNews();
  },
};
</script>

<style scoped>

</style>
