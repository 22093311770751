<template>
  <v-col @click="handleClick" class="text-center pa-3 ma-0" lg="2" md="2" sm="3" cols="6">
    <v-badge overlap v-model="showCount" style="width:100%">
      <template #badge>
        <span>{{ item.document_count }}</span>
      </template>
      <v-card :color="item.color">
        <v-responsive :aspect-ratio="1">
          <v-row class="fill-height ma-0 pa-2" align="center" justify="center">
            <v-icon :size="72" color="white">{{ item.icon }}</v-icon>
          </v-row>
        </v-responsive>
      </v-card>
      <v-col class="icon-title mt-3">{{ item.name }}</v-col>
    </v-badge>
  </v-col>
</template>

<script>
export default {
  name: 'NavigationBlock',
  data() {
    return {};
  },
  props: {
    item: {
      required: true,
      type: Object,
    },
  },
  computed: {
    showCount() {
      if (null === this.item.document_count) {
        return false;
      }

      return true;
    },
  },
  methods: {
    handleClick() {
      switch (this.item.navigation_type_id) {
          // subnav
        case 1:
        default:
          this.$router.push({
            name: 'subnavigation.show',
            params: { navigationId: this.item.id },
          });
          break;
          // documentforce
        case 2:
          this.$router.push({
            name: 'document.index',
            params: { navigationId: this.item.id },
          });
          break;
          // page
        case 3:
          this.$router.push({
            name: 'text.show',
            params: {
              pageId: this.item.page.id,
              pageTitle: this.item.page.title,
            },
          });
          break;
      }
    },
  },
};
</script>

<style scoped>
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  i {
    margin-top: 25px;
  }
}

i.hit-energy {
  background-image: url(/img/hit-energy.png);
  width:            100px;
  height:           100px;
  background-size:  contain;
}

i.hit-bolt {
  background-image: url(/img/hit-bolt.png);
  width:            100px;
  height:           100px;
  background-size:  contain;
}

i.hit-paper {
  background-image: url(/img/hit-paper.png);
  width:            100px;
  height:           100px;
  background-size:  contain;
}

i.hit-shield {
  background-image: url(/img/hit-shield.png);
  width:            100px;
  height:           100px;
  background-size:  contain;
}

i.hit-phone {
  background-image: url(/img/hit-phone.png);
  width:            100px;
  height:           100px;
  background-size:  contain;
}
</style>
