<template>
  <v-col class="page page-navigation mb-3 mt-5">
    <v-col class="headline font-weight-light pl-3">Administratie</v-col>
    <v-container class="pa-0" fluid>
      <v-row>
        <navigation-block class="navigation-block" :item="item" :key="item.id" v-for="item in items"/>
      </v-row>

      <v-col class="headline font-weight-light pl-3 mt-5">Nieuws</v-col>
      <news-overview @noMoreResults="moreBtn = false"
                     @startSearching="searching = true;"
                     @stopActivity="activity = false; searching = false"
                     ref="news">
        <template slot="item" slot-scope="props">
          <news-block
              :content="props.content"
              :date="props.date | date"
              :key="props.id"
              :newsid="props.newsid"
              :title="props.title"
          />
        </template>
      </news-overview>
      <v-btn color="primary" v-if="moreBtn" v-on:click="$refs.news.getNews(true)" :loading="searching">
        Meer nieuws
      </v-btn>
    </v-container>
  </v-col>
</template>

<script>
import NavigationBlock from '../components/NavigationBlock.vue';
import NewsBlock from '../components/news/Block.vue';
import NewsOverview from '../components/news/Overview.vue';

export default {
  components: {
    NewsBlock,
    NewsOverview,
    NavigationBlock,
  },
  name: 'Navigation',
  data() {
    return {
      items: [],
      activity: false,
      name: null,
      moreBtn: true,
      searching: false,
    };
  },
  created() {
    let url = 'navigation';
    this.activity = true;
    this.$http.get(url, {
          params: {
            sortBy: 'order',
            desc: 0,
            page: 1,
            perPage: 10000,
          },
        })
        .then((response) => {
          this.items = response.data.data;
          this.activity = false;
        });
  },
};
</script>

<style scoped>
.navigation-block {
  cursor: pointer;
}
</style>
