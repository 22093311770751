<template>
  <v-col cols="12" md="4">
    <v-card class="d-flex flex-column" height="300px">
      <v-card-text class="flex-grow-1">
        <v-col>
          <v-row class="title"> {{ title }}</v-row>
          <v-row class="caption mb-2"> {{ date }}</v-row>
          <v-row class="content"> {{ content }}</v-row>
        </v-col>
      </v-card-text>
      <v-card-actions>
        <VSpacer/>
        <v-btn right
               color="accent"
               @click="$router.push({ name: 'news.show', params: { newsId:  newsid}})">
          Lees verder
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-col>
</template>

<script>
export default {
  name: 'NewsBlock',
  data() {
    return {};
  },
  props: [
    'title', 'date', 'content', 'newsid',
  ],
};
</script>

<style scoped>
.v-card {
  border-top: 3px solid var(--v-accent-base);
}

.title {
  color:       #616161;
  font-size:   1.2em;
  line-height: 1.4em;
  max-height:  55px;
  overflow:    hidden;
}

.content {
  max-height: 108px;
  overflow:   hidden;
}
</style>
